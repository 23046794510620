import authReducer from './auth/reducer';
import { combineReducers } from 'redux';
import classroomReducer from './classroom/reducer';
import userReducer from './user/reducer';
import examReducer from './exam/reducer';
import bannerReducer from './banner/reducer';

const allReducers = combineReducers({
	auth: authReducer,
	classroom: classroomReducer,
	user: userReducer,
	exam: examReducer,
	banner: bannerReducer,
});

export default allReducers;
