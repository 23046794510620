import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Button, Col, Row } from 'react-bootstrap';
import { MdPerson, MdSchool, MdLocalPostOffice, MdLocalPhone, MdFileUpload } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import { showProfile, updateProfile } from '../../redux/user/action';
import { imgURL } from '../../config/config';

class Profile extends Component {
    constructor(props) {
        super();
        this.state = {
            _id: '',
            fullname: '',
            email: '',
            phone: '',
            avatar: '',
            code: '',
            gender: '',
            selectedFile: null,
            point: '',
            dob: '',
        };
    }

    async componentDidMount() {
        await this.props.showProfile();
        if (this.props.userInfo) {
            var {
                _id,
                fullname,
                email,
                phone,
                avatar,
                classroom,
                code,
                gender,
                point,
                dob,
            } = this.props.userInfo;

            this.setState({
                _id,
                fullname,
                email,
                phone,
                avatar,
                classroom,
                code,
                gender,
                point,
                dob,
            });
        }
    }

    handleChange = e => {
        var name = e.target.name;
        var value = e.target.value;
        this.setState({
            [name]: value,
        });
    }

    onChangeHandler = e => {
        this.setState({
            files: e.target.files[0],
        });
        if (e.target.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
                document.getElementById('sAvatar').src = e.target.result;
            }

            reader.readAsDataURL(e.target.files[0]);
        }
    }

    handleSubmit = async e => {
        e.preventDefault();
        const prams = new FormData();
        let file = null;
        if (this.state.files !== undefined) {
            file = this.state.files;
        }

        prams.append('id', this.state._id);
        prams.append('fullname', this.state.fullname);
        prams.append('email', this.state.email);
        prams.append('phone', this.state.phone);
        prams.append('avatar', file);
        prams.append('classroom', this.state.classroom);
        prams.append('code', this.state.code);

        await this.props.updateProfile(prams);
    }

    render() {
        return (
            <>
                <div className="nap-profile">
                    <h2 className="nap-title-profile">Thông tin cá nhân</h2>
                    <Form className="nap-form-profile">
                        <Row>
                            <Col md={3}>
                                <div className="nap-avatar-profile">
                                    <img alt="" id="sAvatar" src={this.state.avatar != null ? imgURL + this.state.avatar : ''} />
                                </div>
                                <div className="nap-btn-file">
                                    <Form.Control type="file" className="nap-file-input"
                                        name="files" onChange={this.onChangeHandler} />
                                    <span className="nap-icon-file"><MdFileUpload /></span>
                                </div>
                            </Col>
                            <Col md={9}>
                                <Row>
                                    <Col md={12}>
                                        <h2 className="nap-name-profile"><span className="nap-icon-name-profile"><TiTick /></span>{this.state.fullname}</h2>
                                    </Col>
                                    <Col md={12}>
                                        <Row>
                                            <Col md={4}>
                                                <span className="nap-info-profile">
                                                    <span className="nap-icon-profile"><MdPerson /></span>Tên người dùng</span>
                                            </Col>
                                            <Col md={8}>
                                                <Form.Group >
                                                    <Form.Control type="text" name="fullname" value={this.state.fullname}
                                                        onChange={this.handleChange} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={12}>
                                        <Row>
                                            <Col md={4}>
                                                <span className="nap-info-profile">
                                                    <span className="nap-icon-profile"><MdSchool /></span>Trường</span>
                                            </Col>
                                            <Col md={8}>
                                                <Form.Group >
                                                    <Form.Control type="text" name="classroom" value={this.state.classroom} onChange={this.handleChange} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={12}>
                                        <Row>
                                            <Col md={4}>
                                                <span className="nap-info-profile">
                                                    <span className="nap-icon-profile"><MdLocalPostOffice /></span>Email</span>
                                            </Col>
                                            <Col md={8}>
                                                <Form.Group >
                                                    <Form.Control type="email" name="email" value={this.state.email}
                                                        onChange={this.handleChange} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={12}>
                                        <Row>
                                            <Col md={4}>
                                                <span className="nap-info-profile">
                                                    <span className="nap-icon-profile"><MdLocalPhone /></span>Điện thoại</span>
                                            </Col>
                                            <Col md={8}>
                                                <Form.Group >
                                                    <Form.Control type="text" name="phone" value={this.state.phone}
                                                        onChange={this.handleChange} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={12}>
                                        <Button className="nap-btn-profile" variant="primary" type="submit" onClick={this.handleSubmit}>
                                            Cập nhật
                            </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        userInfo: state.user.userInfo || null,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { showProfile, updateProfile },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile));
