import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Col, Container, FormControl, Button, InputGroup, Breadcrumb } from 'react-bootstrap';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { FiHome } from "react-icons/fi";
import { classroomPreview, classroomAccessByCode } from "../../redux/classroom/action";
import Moment from 'moment';
import { Modal } from "antd";
import { FiCheck } from "react-icons/fi";

class ClassroomShow extends Component {
    constructor(props) {
        super();
        this.state = {
            code: null,
            visible: false,
        };
    }

    componentDidMount = async () => {
        let params = { alias: this.props.match.params.alias };
        await this.props.classroomPreview(params);
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.stautsCode === 200 && prevState.visible !== !this.state.visible) {
            this.setState({
                visible: true,
            })
            return false;
        }
    }


    handlChangeCode = e => {
        var name = e.target.name;
        var value = e.target.value;
        this.setState({
            [name]: value,
        })
    }

    handleCancel = () => {
        this.setState({
            visible: false,
        })
    };

    handlClickCode = e => {
        e.preventDefault();
        let code = {
            code: this.state.code,
        }
        this.props.classroomAccessByCode(code);
    }


    checkJoined() {
        if (this.props.joined) {
            return (
                <InputGroup>
                    <InputGroup.Append style={{ width: '100%' }}>
                        <Button style={{ width: '100%', borderRadius: '5px' }} className="nap-primary-btn">Bạn đã tham gia lớp học này</Button>
                    </InputGroup.Append>
                </InputGroup>
            )
        } else {
            return (
                <InputGroup>
                    <FormControl className="access-code"
                        placeholder="Mã truy cập"
                        name="code"
                        value={this.state.code}
                        onChange={this.handlChangeCode}
                    />
                    <InputGroup.Append>
                        <Button onClick={this.handlClickCode} className="nap-primary-btn">Truy cập</Button>
                    </InputGroup.Append>
                </InputGroup>
            )
        }
    }


    fetchClassroom() {
        if (this.props.classroom instanceof Object) {
            let obj = this.props.classroom;
            return (
                <>
                    <Col md={4}>
                        <div className="nap-avata-classroom">
                            <img src={obj !== null ? obj.thumb : ''} alt="" />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="nap-info-classroom">
                            <h3>Thông tin khóa học</h3>
                            <div className="nap-classroom-info-table">
                                <div className="table-item">
                                    <label>Giáo viên</label>
                                    <span>{obj.teacher}</span>
                                </div>
                                <div className="table-item">
                                    <label>Số buổi học</label>
                                    <span>{obj.total_lesson}</span>
                                </div>
                                <div className="table-item">
                                    <label>Số bài kiểm tra</label>
                                    <span>{obj.total_testing}</span>
                                </div>
                                <div className="table-item">
                                    <label>Khai giảng</label>
                                    <span>
                                        {obj.started_at &&
                                            Moment(obj.started_at).format(
                                                'DD/MM/YYYY',
                                            )}
                                    </span>
                                </div>
                                <div className="table-item">
                                    <label>Kết thúc</label>
                                    <span>
                                        {obj.finished_at &&
                                            Moment(obj.finished_at).format(
                                                'DD/MM/YYYY',
                                            )}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="access-by-code">
                            {this.checkJoined()}
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="nap-video-classroom">
                            <h3>Video giới thiệu</h3>
                            <iframe title="Video" height="255" src={obj.video_intro} frameBorder="0"></iframe>
                        </div>
                    </Col>
                    <Modal
                        className="modal-exam success-code-classroom"
                        closable={true}
                        onCancel={this.handleCancel}
                        visible={this.state.visible}
                    >
                        <div className="widget">
                            <div className="widget-right">
                                <p className="widget-info">
                                    <span style={{ color: "#088c0b", fontSize: "30px" }}><FiCheck /></span>
                                     Chúc mừng bạn đã truy cập lớp thành công, chúc bạn học tập thật tốt.
								</p>
                            </div>
                        </div>
                    </Modal>
                </>
            )
        }
    }

    fetchLesson() {
        if (this.props.classroomLessons instanceof Array) {
            return this.props.classroomLessons.map((object, i) =>
                <Col md={2}>
                    <NavLink to={'/lesson/' + object.alias} className="nap-lesson">
                        {object.name}
                    </NavLink>
                </Col>
            );
        }
    }

    render() {
        return (
            <>
                <Header />
                <div className="nap-main-content">
                    <Container>
                        <Row>

                            <Col md={6} className="box-page-title">
                                <h1 className="nap-page-title">{this.props.classroom ? this.props.classroom.name : ''}</h1>
                            </Col>
                            <Col md={6} className="box-breadcrumbs text-right">
                                <Breadcrumb className="nap-breadcrumb">
                                    <Breadcrumb.Item href="/"><FiHome /></Breadcrumb.Item>
                                    <Breadcrumb.Item href={`/category/${this.props.classroomGroup ? this.props.classroomGroup.alias : ''}`}>
                                        {this.props.classroomGroup ? this.props.classroomGroup.name : ''}
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        {this.props.classroom ? this.props.classroom.name : ''}
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                        </Row>
                        <Row>
                            {this.fetchClassroom()}
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div className="nap-lesson-content">
                                    <h3 className="nap-lesson-content-title">Chương trình học</h3>
                                    <Row>
                                        {this.fetchLesson()}
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        classroom: state.classroom.classroom,
        classroomLessons: state.classroom.classroomLessons,
        classroomGroup: state.classroom.classroomGroup,
        joined: state.classroom.joined,
        stautsCode: state.classroom.stautsCode,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { classroomPreview, classroomAccessByCode },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClassroomShow));
