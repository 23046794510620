import * as ActionTypes from './type';
import { sendRequest } from '../../config/api';
import { TOKEN_KEY } from '../../config/app';
import cookie from 'react-cookies';

/**
 * Lấy thông tin của học sinh
 */
export function showProfile(params) {
	return async dispatch => {
		const result = await sendRequest('/user/profile', 'POST', params);
		let userInfo = null;
		if (result && result.data)
			userInfo = result.data;
		dispatch({ type: ActionTypes.SHOW_PROFILE, userInfo });
	};
}
/**
 * Cập nhật thông tin cho học sinh này
 */
export function updateProfile(params) {
	return async dispatch => {
		const result = await sendRequest('/user/update-profile', 'POST', params, false, true);
		let userInfo = null;
		if (result && result.data)
			userInfo = result.data;
		dispatch({ type: ActionTypes.UPDATE_PROFILE, userInfo });
	};
}
/**
 *  * Cập nhật mật khẩu cho học sinh này
 */
export function changePassowrd(params) {
	return async dispatch => {
		const result = await sendRequest('/user/change-password', 'POST', params, false, true);
		if (result && result.code === 200) {
			cookie.save(TOKEN_KEY, result.data.token, { path: '/' });
			let token = result.data.token;
			dispatch({ type: ActionTypes.CHANGE_PASSWORD, token });
		}
		return;
	};
}
/**
 * Danh sách các lớp mà học sinh này đã tham gia
 */
export function listClassroom(params) {
	return async dispatch => {
		const result = await sendRequest('/classroom/list', 'POST', params);
		let total = 0;
		let classrooms = [];
		let limit = 20;
		let page = 1;
		if (result) {
			classrooms = result.data.records;
			total = result.data.total;
			limit = result.data.limit;
			page = result.page;
		}

		dispatch({
			type: 'PAGING',
			page: page
		});

		dispatch({
			type: ActionTypes.LIST_CLASSROOM,
			classrooms,
			total,
			limit
		});
	};
}
/**
* Danh sách các bài đã làm mà học sinh này đã tham gia
*/
export function listTesting(params) {
	return async dispatch => {
		const result = await sendRequest('/testing/list', 'POST', params);
		let total = 0;
		let testings = [];
		let limit = 20;
		let page = 1;
		if (result) {
			testings = result.data.records;
			total = result.data.total;
			limit = result.data.limit;

		}

		dispatch({
			type: 'PAGING',
			page: page
		});

		dispatch({
			type: ActionTypes.LIST_TESTING,
			testings,
			total,
			limit
		});
	};
}

/**
*Xem chi tiết  bài đã làm mà học sinh này đã tham gia
*/
export function showTesting(params) {
	return async dispatch => {
		const result = await sendRequest('/testing/detail', 'POST', params);
		let dataShowTesting = null;
		if (result && result.data) {
			dataShowTesting = result.data;
		}

		dispatch({
			type: ActionTypes.SHOW_TESTING,
			dataShowTesting
		});
	};
}


/**
*Xem chi tiết  lời giải  mà học sinh này đã tham gia
*/
export function viewAnswer(params) {
	return async dispatch => {
		const result = await sendRequest('/question/answer', 'POST', params);
		let answerData = null;
		if (result && result.data) {
			answerData = result.data;
			if (!answerData.video_type) {
				answerData.video_type = 'GOOGLE_DRIVE';
			}
		}
		dispatch({
			type: ActionTypes.VIEW_ANSWER,
			answerData
		});
	};
}

/**
 * Danh sách bảng xếp hạng
 */
export function listRank(params) {
	return async dispatch => {
		const result = await sendRequest('/user/rank', 'POST', params);
		let total = 0;
		let ranks = [];
		let limit = 20;
		let page = 1;
		if (result) {
			ranks = result.data.records;
			total = result.data.total;
			limit = result.data.limit;
			page = result.page;
		}

		dispatch({
			type: 'PAGING',
			page: page,
		});

		dispatch({
			type: ActionTypes.LIST_RANK,
			ranks,
			total,
			limit
		});
	};
}