import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Col, Container, Breadcrumb } from 'react-bootstrap';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { FiHome } from "react-icons/fi";
import { lessonPreview } from "../../redux/classroom/action";
import { listExamByLesson } from "../../redux/exam/action";

class ListTest extends Component {
    constructor(props) {
        super();
        this.state = {
            code: null,
            visible: false,
        };
    }

    componentDidMount = async () => {
        let params = { alias: this.props.match.params.alias };
        await this.props.lessonPreview(params);
        if (this.props.lesson) {
            await this.props.listExamByLesson({
                lesson_id: this.props.lesson._id,
                classroom_id: this.props.lesson.classroom.id
            });
        }
    };

    fetchExams() {
        if (this.props.exams instanceof Array) {
            return this.props.exams.map((object, i) =>
                <Col md={3}>
                    <NavLink to={`/test-online/${object._id}-${this.props.lesson._id}-${this.props.lesson.classroom.id}`} className="nap-lesson nap-list-test">
                        <div className="name">{object.name}</div>
                        <div>
                            <p>Thời gian: <b>{object.time} phút</b></p>
                        </div>
                    </NavLink>
                </Col>
            )
        }
    }

    render() {
        return (
            <>
                <Header />
                <div className="nap-main-content">
                    <Container>
                        <Row>
                            <Col md={6} className="box-page-title">
                                <h1 className="nap-page-title">{this.props.lesson ? this.props.lesson.name : ''}</h1>
                            </Col>
                            <Col md={6} className="box-breadcrumbs text-right">
                                <Breadcrumb className="nap-breadcrumb">
                                    <Breadcrumb.Item href="/"><FiHome /></Breadcrumb.Item>
                                    <Breadcrumb.Item href={`/test-online/${this.props.classroom ? this.props.classroom.alias : ''}/chuyen-de`}>
                                        {this.props.classroom ? this.props.classroom.name : ''}
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        {this.props.lesson ? this.props.lesson.name : ''}
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div className="nap-lesson-content">
                                    <h3 className="nap-lesson-content-title">Danh sách bài thi</h3>
                                    <Row>
                                        {this.fetchExams()}
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        lesson: state.classroom.lesson,
        lessonVideos: state.classroom.lessonVideos,
        classroom: state.classroom.classroom,
        classroomGroup: state.classroom.classroomGroup,
        exams: state.exam.exams,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { lessonPreview, listExamByLesson },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListTest));
