import React, { Component } from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from '../components/home/Home';
import Signin from '../components/auth/Signin';
import Signup from '../components/auth/Signup';
import Verify from '../components/auth/Verify';
import ForgotPassword from '../components/auth/ForgotPassword';
import ClassroomList from '../components/classroom/Classroom';
import ClassroomShow from '../components/classroom/ClassroomShow';
import LessonList from '../components/classroom/Lesson';
import LessonShow from '../components/classroom/LessonShow';
import Account from '../components/account/Account';
import DoExam from '../components/exam/DoExam';
import TestDetail from '../components/account/TestDetail';
import Ranking from '../components/ranking/Ranking';
import TestOnline from '../components/test/TestOnline';
import Thematic from '../components/test/Thematic';
import ListTest from '../components/test/ListTest';
import DetailTest from '../components/test/DetailTest';

class Master extends Component {
	constructor(props) {
		super();
		this.state = {

		};
	}

	render() {
		return (
			<>
				<Switch>
					<Route exact path="/" component={Home} />
					<Route exact path="/signin" component={Signin} />
					<Route exact path="/signup" component={Signup} />
					<Route exact path="/reset-password" component={ForgotPassword} />
					<Route exact path="/category/:alias" component={ClassroomList} />
					<Route exact path="/classroom/:alias" component={ClassroomShow} />
					<Route exact path="/khoa-hoc" component={ClassroomList} />
					<Route exact path="/lesson/:alias" component={LessonList} />
					<Route exact path="/lesson-view/:alias" component={LessonShow} />
					<Route exact path="/do-exam/:id" component={DoExam} />
					<Route path={`/testing/:id`} component={TestDetail} />
					<Route path="/my" component={Account} />
					<Route path="/bang-xep-hang" component={Ranking} />
					<Route path="/verify/:token" component={Verify} />
					<Route exact path="/test-online" component={TestOnline} />
					<Route exact path="/test-online/:alias/chuyen-de" component={Thematic} />
					<Route exact path="/test-online/lop/:alias" component={ListTest} />
					<Route exact path="/test-online/:id" component={DetailTest} />
				</Switch>
				<ToastContainer />
			</>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{},
		dispatch,
	);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Master));
