import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Carousel, Image } from 'react-bootstrap';
import { homeSlider } from '../../redux/banner/action';

class Slider extends Component {
    constructor(props) {
        super();
        this.state = {

        };
    }

    async componentDidMount() {
        let params = { "position": "HOME_SLIDER" };
        await this.props.homeSlider(params);
    }

    fechHomeSlider() {
        if (this.props.dataHomeSlider instanceof Array) {
            return this.props.dataHomeSlider.map((obj, i) =>
                <Carousel.Item key={i}>
                    <a href="/"><Image
                        src={obj.image !== null ? obj.image : ''}
                        alt={obj.name} />
                    </a>
                </Carousel.Item>
            )
        }
    }

    render() {
        return (
            <div className="nap-home-slider">
                <Carousel>
                    {this.fechHomeSlider()}
                </Carousel>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        dataHomeSlider: state.banner.dataSlider
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { homeSlider },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Slider));