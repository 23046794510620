import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Row, Col, Form, Button, NavLink } from 'react-bootstrap';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { forgotPassword } from '../../redux/auth/action';

class ForgotPassword extends Component {
    constructor(props) {
        super();
        this.state = {
            email: '',
        };
    }

    componentDidMount() {
        const { token, updateToken } = this.context;
        this.setState({
            token,
            updateToken,
        });
    }
    handleChange = e => {
        var name = e.target.name;
        var value = e.target.value;
        this.setState({
            [name]: value,
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        const data = {
            email: this.state.email,
        };
        this.props.forgotPassword(data);
    };

    render() {
        return (
            <>
                <Header />
                <div className="nap-main-content">
                    <Container>
                        <Row>
                            <Col md={12}>
                                <h2 className="nap-title-form">Quên mật khẩu</h2>
                                <Form className="nap-form-signup">
                                    <Form.Group controlId="formBasicPhone">
                                        <Form.Label><br />Nhập Email bạn đã đăng ký tài khoản</Form.Label>
                                        <Form.Control type="text" placeholder="" name="email" onChange={this.handleChange}
                                            value={this.state.email} />
                                    </Form.Group>
                                    <Button className="nap-btn-signin" variant="primary" type="submit" onClick={this.handleSubmit}>
                                        Lấy lại mật khẩu
                                </Button>
                                    <Form.Group controlId="formBasicPassword">
                                        <p><br /><NavLink to="/signin">Quay lại trang đăng nhập</NavLink></p>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        token: state.auth.token,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ forgotPassword }, dispatch);
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ForgotPassword),
);