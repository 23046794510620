import * as ActionTypes from './type';
const initialState = {
	classrooms: [],
	classroom: {},
	classroomLessons: [],
	classroomGroupData: [],
	classroomGroup: {},
	lesson: null,
	lessonVideos: [],
	total: 0,
	page: 1,
	limit: 20,
	numCanView: null,
	totalViewLimit: 0,
	ids: [],
	overviewClass: null,
};
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.LIST_CLASSROOM:
			return {
				...state,
				classrooms: action.classrooms,
				total: action.total,
				limit: action.limit,
				ids: [],
			};
		case ActionTypes.LIST_GROUP_CLASSROOM:
			return {
				...state,
				groupClassrooms: action.groupClassrooms,
				classroomGroup: action.classroomGroup
			};
		case ActionTypes.LIST_GROUP_BY_YEAR:
			return {
				...state,
				classroomGroupData: action.classroomGroupData
			};
		case ActionTypes.LIST_CLASSROOM_PREVIEW:
			return {
				...state,
				classroom: action.classroom,
				classroomLessons: action.classroomLessons,
				classroomGroup: action.classroomGroup,
				joined: action.joined,
			};
		case ActionTypes.LIST_LESSON_PREVIEW:
			return {
				...state,
				lesson: action.lesson,
				lessonVideos: action.lessonVideos,
				classroom: action.classroom,
				classroomGroup: action.classroomGroup,
			};
		case ActionTypes.LESSON_VIDEO_PREVIEW:
			return {
				...state,
				currentVideo: action.currentVideo,
				lessonVideos: action.lessonVideos,
				lesson: action.lesson,
				numCanView: action.numCanView,
				totalViewLimit: action.totalViewLimit
			};
		case ActionTypes.CLASSROOM_ACCESS_BY_CODE:
			return {
				...state,
				dataAccessByCode: action.dataAccessByCode,
				stautsCode: action.stautsCode,
			};
		default:
			return state;
	}
};

export default reducer;
