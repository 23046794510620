const initialState = {
    status: false,
    token: null,
    user: {}
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN':
            return {
                ...state,
                status: true,
                token: action.token,
                user: action.user
            }
        case 'VERIFY':
            return {
                ...state,
                status: true,
                token: action.token
            }
        case 'LOGOUT':
            return {
                ...state,
                status: false,
                token: null
            }
        case 'SIGNUP':
            return {
                ...state,
                status: true,
                token: action.token
            }
            default:
                return state;
        }
}

export default reducer;