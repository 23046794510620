import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Col, Container, Breadcrumb } from 'react-bootstrap';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { FiHome } from "react-icons/fi";
import { classroomPreview, classroomAccessByCode } from "../../redux/classroom/action";
import { AiFillBook } from "react-icons/ai";

class Thematic extends Component {
    constructor(props) {
        super();
        this.state = {
            code: null,
            visible: false,
        };
    }

    componentDidMount = async () => {
        let params = { alias: this.props.match.params.alias };
        await this.props.classroomPreview(params);
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.stautsCode === 200 && prevState.visible !== !this.state.visible) {
            this.setState({
                visible: true,
            })
            return false;
        }
    }


    handlChangeCode = e => {
        var name = e.target.name;
        var value = e.target.value;
        this.setState({
            [name]: value,
        })
    }

    handleCancel = () => {
        this.setState({
            visible: false,
        })
    };

    handlClickCode = e => {
        e.preventDefault();
        let code = {
            code: this.state.code,
        }
        this.props.classroomAccessByCode(code);
    }


    fetchLesson() {
        if (this.props.classroomLessons instanceof Array) {
            return this.props.classroomLessons.map((object, i) =>
                <Col md={3}>
                    <NavLink to={'/test-online/lop/' + object.alias} className="nap-lesson nap-thematic">
                        <span className="icon-thematic"><AiFillBook /></span> {object.name}
                    </NavLink>
                </Col>
            );
        }
    }

    render() {
        return (
            <>
                <Header />
                <div className="nap-main-content">
                    <Container>
                        <Row>
                            <Col md={6} className="box-page-title">
                                <h1 className="nap-page-title">{this.props.classroom ? this.props.classroom.name : ''}</h1>
                            </Col>
                            <Col md={6} className="box-breadcrumbs text-right">
                                <Breadcrumb className="nap-breadcrumb">
                                    <Breadcrumb.Item href="/"><FiHome /></Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        {this.props.classroom ? this.props.classroom.name : ''}
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div className="nap-lesson-content">
                                    <h3 className="nap-lesson-content-title">Chuyên đề</h3>
                                    <Row>
                                        {this.fetchLesson()}
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        classroom: state.classroom.classroom,
        classroomLessons: state.classroom.classroomLessons,
        classroomGroup: state.classroom.classroomGroup,
        joined: state.classroom.joined,
        stautsCode: state.classroom.stautsCode,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { classroomPreview, classroomAccessByCode },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Thematic));
