import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { goDashboard } from '../../config/auth';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../layout/Header';
import Footer from '../layout/Footer';

import { verifyAccount } from '../../redux/auth/action';

class Verify extends Component {
    constructor(props) {
        super();
        this.state = {};
    }

    async componentDidMount() {        
        const params = {
            token: this.props.match.params.token
        };
        await this.props.verifyAccount(params);
        if (this.props.token !== null) {
            goDashboard();
        }
    }

    render() {
        return (
            <>
                <Header />
                <div className="nap-main-content">
                    <Container>
                        <Row>
                            <Col md={12}>
                                <h2 className="nap-title-form">Kích hoạt tài khoản!</h2>                                
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {    
    return {
        token: state.auth.token,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { verifyAccount },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Verify));
