import * as ActionTypes from './type';
import { sendRequest } from '../../config/api';
import { URL_LOGIN, TOKEN_KEY } from '../../config/app';
import cookie from 'react-cookies';

export const logout = () => {
	cookie.remove(TOKEN_KEY, { path: '/' });
	window.location.href = URL_LOGIN;
	return;
};

export function verifyAccount(params) {
	return async dispatch => {
		const result = await sendRequest('/auth/verify', 'POST', params, false, true);
		if (result && result.code === 200) {
			cookie.save(TOKEN_KEY, result.data.token, { path: '/' });
			let token = result.data.token;			
			dispatch({ type: ActionTypes.VERIFY, token });
		}

		return;
	};
}

export function login(params) {
	return async dispatch => {
		const result = await sendRequest('/auth/signin', 'POST', params, false, true);
		if (result && result.code === 200) {
			cookie.save(TOKEN_KEY, result.data.token, { path: '/' });
			let token = result.data.token;
			let user = result.data;
			dispatch({ type: ActionTypes.LOGIN, token, user });
		}

		return;
	};
}

export function signup(params) {
	return async dispatch => {
		const result = await sendRequest('/auth/signup', 'POST', params, false, true);

		if (result && result.code === 200) {
			cookie.save(TOKEN_KEY, result.data.token, { path: '/' });
			let token = result.data.token;
			dispatch({ type: ActionTypes.SIGNUP, token });
		}
		return;
	};
}

export function forgotPassword(params) {
	return async dispatch => {
		const result = await sendRequest('/reset-password', 'POST', params, false, true);
		if (result.code === 200) {
			// Todo
		}
		return;
	};
}
