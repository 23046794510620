import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Table, Row, Col, Container, Breadcrumb } from 'react-bootstrap';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { FiHome } from "react-icons/fi";
import { listRank } from "../../redux/user/action";
import { imgURL } from "../../config/config";
import { round } from 'lodash';
import Pagination from 'react-js-pagination';

class RowRank extends Component {
    constructor(props) {
        super();
        this.state = {
            numRank: "",
            stt: "",
            keyword: "",
            css: "nap-text-stt",
        };
    }

    componentDidMount() {
        if (this.props.obj.total_question !== '') {
            if (this.props.index === 0) {
                console.log("in");
                this.setState({
                    numRank: "nap-rank-gold"
                })
            } else if (this.props.index === 1) {
                this.setState({
                    numRank: "nap-rank-sliver"
                })
            } else if (this.props.index === 2) {
                this.setState({
                    numRank: "nap-rank-copper"
                })
            } else {
                this.setState({
                    numRank: "nap-text-stt"
                })
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        var top1 = document.getElementById("rank-0");
        var top2 = document.getElementById("rank-1");
        var top3 = document.getElementById("rank-2");
        if (prevState.keyword !== this.props.keyword) {

            if (top1)
                top1.classList.remove("nap-rank-gold");

            if (top2)
                top2.classList.remove("nap-rank-sliver");

            if (top3)
                top3.classList.remove("nap-rank-copper");
        } else {
            if (top1)
                top1.classList.add("nap-rank-gold");

            if (top2)
                top2.classList.add("nap-rank-sliver");

            if (top3)
                top3.classList.add("nap-rank-copper");
        }
    }

    render() {
        return (
            <tr>
                <td id={"rank-" + this.props.index} className={"nap-item-rank " + this.state.numRank}>
                    <p>{this.props.index + 1}</p>
                </td>
                <td>
                    <div className="nap-avatar-ranking">
                        <img src={this.props.obj.avatar !== null ? imgURL + this.props.obj.avatar : 'https://nguyenanhphong.vn/assets/img/user.jpg'}
                            title="" alt="" />
                    </div>
                    <span className="nap-name-rank">
                        {this.props.obj.fullname}
                    </span>
                </td>
                <td>{this.props.obj.total_point ? this.props.obj.total_point : 0}</td>
                <td>
                    {this.props.obj.total_question > 0 ?
                        (
                            <>
                                <b>
                                    {round((this.props.obj.num_right / this.props.obj.num_wrong) * 100, 2)}%
                                                    </b>
                                <span> ({this.props.obj.num_right}/{this.props.obj.num_wrong})</span>
                            </>
                        ) : (
                            <>
                                <>
                                    <b>
                                        0%
                                                    </b>
                                    <span> (0/0)</span>
                                </>
                            </>
                        )
                    }

                </td>
            </tr>
        );
    }
}

class Ranking extends Component {
    constructor(props) {
        super();
        this.state = {
            limit: 100,
            page: 1,
            keyword: '',
            stt: true,
        };
    }

    async componentDidMount() {
        await this.props.listRank(this.getData());
        if (this.props.limit) {
            await this.setState({
                limit: this.props.limit,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.keyword !== this.state.keyword) {
            this.props.listRank(this.getData());
            this.checkStt();
        }
    }

    checkStt() {
        if (this.state.keyword !== '') {
            this.setState({
                stt: false
            })
        } else {
            this.setState({
                stt: true
            })
        }
    }

    fetchRank() {
        if (this.props.ranks instanceof Array) {
            return this.props.ranks.map((object, i) =>
                <RowRank
                    obj={object}
                    index={i}
                    keyword={this.state.keyword}
                    {...this.props}
                />
            )
        }
    }
    getData = (pageNumber = 1) => {
        const data = {
            page: pageNumber,
            limit: this.state.limit,

        };
        if (this.state.keyword != null) {
            data['keyword'] = this.state.keyword;
        }
        return data;
    };

    handleChangePage = async pageNumber => {
        await this.props.listRank(this.getData(pageNumber));
    };

    handleChange = e => {
        var name = e.target.name;
        var value = e.target.value;
        this.setState({
            [name]: value,
        });
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.listRank(this.getData());
    }
    render() {

        return (
            <>
                <Header />
                <div className="nap-main-content">
                    <Container>
                        <Row>
                            <Col md={12}>
                                <div className="overflow-hidden pb-3">
                                    <Breadcrumb className="nap-breadcrumb float-right">
                                        <Breadcrumb.Item href="/"><FiHome /></Breadcrumb.Item>
                                        <Breadcrumb.Item active>Bảng xếp hạng</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                                <div className="nap-table-ranking">
                                    <h3 className="mb-4">Bảng xếp hạng</h3>
                                    <div className="float-left">
                                        <span className="mt-3 d-block">
                                            Tổng số: <b>{this.props.total}</b> Học sinh
                                        </span>
                                    </div>
                                    <div className="float-right">
                                        <div className="nap-search-testing p-0">
                                            <form className=""><div className=" form-group">
                                                <input name="keyword" placeholder="Tìm kiềm theo tên, tài khoản..."
                                                    onChange={this.handleChange}
                                                    type="text" id="formBasicEmail" className="form-control" value={this.state.keyword} />
                                            </div>
                                            </form>
                                        </div>
                                    </div>
                                    <Table striped bordered className="text-center">
                                        <thead>
                                            <tr className="nap-custom-thead-ranking">
                                                <th width="100px">{this.state.stt === true ? "Hạng" : "STT"}</th>
                                                <th>Họ và tên</th>
                                                <th width="200px">Tổng điểm</th>
                                                <th width="200px">Tỉ lệ đúng</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.fetchRank()}
                                        </tbody>
                                    </Table>
                                </div>
                                <div className="nap-limit-table-rank">
                                    {this.props.total !== 0 ?
                                        (
                                            <>
                                                {
                                                    this.props.total > this.props.limit ?
                                                        (
                                                            <Pagination
                                                                activePage={this.props.page}
                                                                itemsCountPerPage={this.props.limit}
                                                                totalItemsCount={this.props.total}
                                                                pageRangeDisplayed={10}
                                                                onChange={this.handleChangePage}
                                                            />
                                                        ) : null
                                                }
                                            </>

                                        ) : (
                                            "Không có bản ghi nào"
                                        )
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        ranks: state.user.ranks,
        limit: state.user.limit,
        page: state.user.page,
        total: state.user.total,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { listRank },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Ranking));