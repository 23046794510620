import { baseURL } from './config';
import axios from 'axios';
import { toast } from 'react-toastify';
import cookie from 'react-cookies';
import { URL_LOGIN, TOKEN_KEY } from './app';

export const initAPI = (auth = true) => {
    axios.defaults.baseURL = baseURL;
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    if (auth) {
        const token = cookie.load(TOKEN_KEY);
        if (token) {
            axios.defaults.headers.common['Authorization'] = token;
        }
    }
}
/**
 * 
 * @param {*} url Đường API gửi lên Server
 * @param {*} method Phương thức gửi lên Server. Có thể là POST hoặc GET, DELETE
 * @param {*} params Dữ liệu từ client gửi lên Server. Hay là các dữ liệu nhập từ Form
 * @param {*} auth Nếu = true thì gửi kèm Token (token) lên server để xác thực. Dùng cho những chức năng bắt buộc phải đăng nhập
 * @param {*} _showMessage Là trạng thái hiện thông báo hoặc không hiện thông báo khi thực hiện thành công.
 * @param {*} _redirectToLogin Là trạng thái muốn chuyển về trang Login
 */
export const sendRequest = async (url, method = "POST", params, auth = true, _showMessage = false, _redirectToLogin = true) => {
    initAPI(auth);
    if (method === 'POST') {
        return await axios
            .post(url, params)
            .then(res => {
                notify(res, _showMessage);
                if (res.status === 200 || res.status === 201) {
                    return res.data;
                }

                return null;
            }).catch(async err => {
                responseError(err);
                return null;
            });
    } else {
        return await axios
            .get(url, params)
            .then(res => {
                notify(res, _showMessage);
                if (res.statusCode === 200) {
                    return res.data;
                }

                return null;
            }).catch(async err => {
                responseError(err);
            });
    }

};

export const redirectToLogin = code => {
    cookie.remove('token');
    window.location.href = URL_LOGIN;
};

export const notify = (res, showSuccess = true) => {
    let msg = '';
    if (res.data.code !== 200) {
        msg = res.data.message || 'Cảnh báo!';
        toast.warning(msg, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    if (res.data.code === 200 && showSuccess) {
        msg = res.data.message || 'Thành công!';
        toast.success(msg, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
};

export const responseError = (err, _redirectToLogin = true) => {
    if (err && err.response) {
        if (err.response.status === 401) {
            cookie.remove(TOKEN_KEY, { path: '/' });
            if (_redirectToLogin)
                redirectToLogin();
        } else {
            if (err.response.data && err.response.data.message) {
                toast.error(err.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return false;
            }
        }
    } else {
        toast.error('Lỗi! Vui lòng liên hệ để được hỗ trợ.', {
            position: toast.POSITION.TOP_RIGHT
        });
        return false;
    }
};
