import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Image, Row, Col } from 'react-bootstrap';
import { homeFeaturedBox } from '../../redux/banner/action';

class Slider extends Component {
    constructor(props) {
        super();
        this.state = {

        };
    }

    async componentDidMount() {
        let params = { "position": "HOME_FEATURED_BOX" };
        await this.props.homeFeaturedBox(params);
    }

    fechHomeSlider() {
        if (this.props.dataHomeFeaturedBox instanceof Array) {
            return this.props.dataHomeFeaturedBox.map((obj, i) =>
                <Col md={3} xs={6} className="item mb-2" key={i}>
                    <a style={{ display: 'block' }} href="/"><Image
                        src={obj.image !== null ? obj.image : ''}
                        alt={obj.name} />
                    </a>
                </Col>
            )
        }
    }

    render() {

        return (
            <div className="nap-home-featured-box">
                <Row>
                    {this.fechHomeSlider()}
                </Row>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        dataHomeFeaturedBox: state.banner.dataFeaturedBox
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { homeFeaturedBox },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Slider));