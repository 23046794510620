import * as ActionTypes from "./type";
import { isUndefined } from "util";
const initialState = {
    data: {},
    initAnswers: [],
    answers: [],
    dataAnswer: [],

    testings: [],
    questions: [],
    testing: null,
    exams: null,
    total: 0,
    page: 1,
    limit: 20,
    ids: [],
    checkAll: false,
    qtyTrue: 0,
    totalAnswer: 0,
    countPending: 0,
    result: null,
    answerData: null,
    examPreview: null,
    errMessage: null,
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.EXAM_DETAIL:
            return {
                ...state,
                data: action.data,
                totalAnswer: 0,
            };

        case ActionTypes.RESET:
            return {
                ...state,
                answers: [],
                initAnswers: [],
                totalAnswer: 0,
                result: null,
            };
        case ActionTypes.CREATE_TESTING:
            return {
                ...state,
                statusCode: action.statusCode,
                dataCreate: action.dataCreate,
            };
        case ActionTypes.INIT_ANSWER:
            const initAnswers = state.initAnswers;
            return {
                ...state,
                initAnswers: Object.assign(
                    initAnswers,
                    initAnswers.push(action.answer)
                ),
            };
        case ActionTypes.CLICK_ANSWER:
            const answers = state.answers;
            const initAnswersCopy = state.initAnswers;
            var totalAnswer = state.totalAnswer;

            const check = answers.find(
                (ele) => ele.question_id === action.answer.question_id
            );

            var newAnswers = null;

            if (isUndefined(check)) {
                newAnswers = Object.assign(
                    answers,
                    answers.push(action.answer)
                );
                totalAnswer += 1;
            } else {
                answers.forEach((ele) => {
                    if (ele.question_id === action.answer.question_id) {
                        ele.value = action.answer.value;
                    }
                });

                newAnswers = Object.assign([], answers);
            }

            initAnswersCopy.forEach((ele) => {
                newAnswers.forEach((item) => {
                    if (ele.question_id === item.question_id) {
                        ele.value = item.value;
                    }
                });
            });

            return {
                ...state,
                answers: newAnswers,
                totalAnswer: totalAnswer,
                dataAnswer: initAnswersCopy,
            };
        case ActionTypes.VIEW_ANSWER:
            return {
                ...state,
                answerData: action.answerData,
            };
        case ActionTypes.RESET_VIEWDATA:
            return {
                ...state,
                answerData: null,
            };
        case ActionTypes.EXAM_PREVIEW:
            return {
                ...state,
                examPreview: action.examPreview,
            };
        case ActionTypes.ERROR:
            return {
                ...state,
                errMessage: action.errMessage,
            };
        case ActionTypes.ERROR_RESET:
            return {
                ...state,
                errMessage: null,
            };
        case ActionTypes.LIST_BY_LESSON:
            return {
                ...state,
                exams: action.exams,
                errMessage: null,
            };
        default:
            return state;
    }
};

export default reducer;
