import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cookie from 'react-cookies';
import { TOKEN_KEY } from './config/app';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/app.css';
import './assets/js/custom.js';

import Master from './components/Master';

class App extends Component {
	constructor(props) {
		super(props);

		const token = cookie.load(TOKEN_KEY) || null;
		this.state = {
			token: token,
		};
	}

	render() {
		return (
			<>
				<Master />
			</>
		);
	}
}

function mapStateToProps(state) {
	return {
		token: state.auth.token,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({}, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
