import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { goDashboard } from '../../config/auth';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Header from '../layout/Header';
import Footer from '../layout/Footer';

import {login} from '../../redux/auth/action';

class Signin extends Component {
    constructor(props) {
        super();
        this.state = {
            email: '',
            password: '',
        };
    }

    componentDidMount() {
        goDashboard();
    }

    handleChange = e => {
        var name = e.target.name;
        var value = e.target.value;
        this.setState({
            [name]: value,
        });
    }

    handleSubmit = async e => {
        e.preventDefault();
        const params = {
            email: this.state.email,
            password: this.state.password,
        }
        await this.props.login(params);
        if (this.props.token !== null) {
            goDashboard();
        }
    }

    render() {
        return (
            <>
                <Header />
                <div className="nap-main-content">
                    <Container>
                        <Row>
                            <Col md={12}>
                            <h2 className="nap-title-form">Đăng nhập</h2>
                            <Form className="nap-form-signup">
                                <Form.Group controlId="formBasicPhone">
                                    <Form.Label>Số điện thoại</Form.Label>
                                    <Form.Control type="text" placeholder="" name="email" onChange={this.handleChange}
                                    value={this.state.email}/>
                                </Form.Group>
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>Mật khẩu</Form.Label>
                                    <Form.Control type="password" placeholder="" name="password" onChange={this.handleChange}
                                    value={this.state.password} />
                                </Form.Group>
                                <Form.Group controlId="formBasicPassword">
                                    <p>Bạn chưa có tài khoản? <NavLink to="/signup">Đăng ký</NavLink></p>
                                </Form.Group>
                                <Button className="nap-btn-signin"  variant="primary" type="submit" onClick={this.handleSubmit}>
                                    Đăng nhập
                                </Button>
                                    <Form.Group controlId="formBasicPassword">
                                        <p><br /><NavLink to="/reset-password">Quên mật khẩu?</NavLink></p>
                                    </Form.Group>
                            </Form>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        token: state.auth.token,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {login},
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Signin));
