import React, { Component } from 'react';
import { NavLink, withRouter, Switch, Route } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { Row, Container, Col } from 'react-bootstrap';
import { MdPerson, MdClass, MdDescription, MdVpnKey } from "react-icons/md";
import { checkLogin } from '../../config/auth';
import Profile from '../account/Profile';
import Classroom from '../account/Classroom';
import Testing from '../account/Testing';
import Notification from '../account/Notification';
import ChangePassword from '../account/ChangePassword';

class Account extends Component {
    constructor(props) {
        super();
        this.state = {

        };
    }

    componentDidMount() {
        checkLogin();
    }

    render() {
        return (
            <>
                <Header />
                <div className="nap-main-content">
                    <Container>
                        <Row>
                            <Col md={3} className="nap-lesson-left">
                                <div className="nap-lesson-box">
                                    <h2 className="nap-title-lesson">Thông tin tài khoản</h2>
                                    <ul className="nap-list-lesson">
                                        <li>
                                            <NavLink to="/my/profile">
                                                <span className="nap-icon-account"><MdPerson /></span>Thông tin cá nhân</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/my/classrooms">
                                                <span className="nap-icon-account"><MdClass /></span>Lớp đã tham gia</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/my/testings">
                                                <span className="nap-icon-account"><MdDescription /></span>Bài đã làm</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/my/change-password">
                                                <span className="nap-icon-account"><MdVpnKey /></span>Đổi mật khẩu</NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col md={9} className="nap-lesson-right">
                                <div className="nap-test-lesson">
                                    <Switch>
                                        <Route path={`/my/profile`} render={() => <Profile />} />
                                        <Route path={`/my/classrooms`} render={() => <Classroom />} />
                                        <Route path={`/my/testings`} render={() => <Testing />} />
                                        <Route path={`/my/notification`} render={() => <Notification />} />
                                        <Route path={`/my/change-password`} render={() => <ChangePassword />} />
                                    </Switch>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {},
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Account));
