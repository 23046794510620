import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReCAPTCHA from "react-google-recaptcha";
import { goDashboard } from '../../config/auth';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Header from '../layout/Header';
import Footer from '../layout/Footer';

import { signup } from '../../redux/auth/action';

const recaptchaRef = React.createRef();

class Signup extends Component {
    constructor(props) {
        super();
        this.state = {
            fullname: '',
            phone: '',
            email: '',
            password: '',
            captcha: ''
        };
    }

    componentDidMount() {
        goDashboard();
    }

    onChangeRecaptcha = value => {
        this.setState({
            captcha: value
        })
    }

    handleChange = e => {
        var name = e.target.name;
        var value = e.target.value;
        
        this.setState({
            [name]: value,
        });
    };

    handleSubmit = async e => {
        e.preventDefault();        
        const params = {
            fullname: this.state.fullname,
            phone: this.state.phone,
            email: this.state.email,
            password: this.state.password,
            captcha: this.state.captcha       
        };        

        await this.props.signup(params);
        this.setState({
            fullname: '',
            phone: '',
            email: '',
            password: '',
            captcha: ''
        });
    };

    render() {
        return (
            <>
                <Header />
                <div className="nap-main-content">
                    <Container>
                        <Row>
                            <Col md={12}>
                                <h2 className="nap-title-form">Đăng ký</h2>
                                <Form className="nap-form-signup">
                                    <Form.Group controlId="formBasicName">
                                        <Form.Label>Họ và tên</Form.Label>
                                        <Form.Control type="text" name="fullname" onChange={this.handleChange} value={this.state.fullname} />
                                    </Form.Group>
                                    <Form.Group controlId="formBasicPhone">
                                        <Form.Label>Số điện thoại</Form.Label>
                                        <Form.Control type="text" name="phone" onChange={this.handleChange} value={this.state.phone} />
                                    </Form.Group>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" name="email" onChange={this.handleChange} value={this.state.email} />
                                    </Form.Group>
                                    <Form.Group controlId="formBasicPassword">
                                        <Form.Label>Mật khẩu</Form.Label>
                                        <Form.Control type="password" name="password" onChange={this.handleChange} value={this.state.password} />
                                    </Form.Group>
                                    <Form.Group controlId="formBasicPassword">
                                        <p>Bạn đã có tài khoản? <NavLink to="/signin">Đăng nhập</NavLink></p>
                                    </Form.Group>
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey="6LcIaqkZAAAAAPmDqLp4RUXResRbZYSAaXoua2x-"
                                        onChange={this.onChangeRecaptcha}
                                    />
                                    <Button className="nap-btn-signin" variant="primary" type="button" onClick={this.handleSubmit}>
                                        Đăng ký
                                </Button>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        token: state.auth.token,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { signup },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Signup));
