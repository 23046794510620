import * as ActionTypes from './type';
import { sendRequest } from '../../config/api';

export function listExamByLesson(params) {
    return async dispatch => {
        const result = await sendRequest('/exam/list-by-lesson', 'POST', params);
        let total = 0;
        let exams = [];
        let limit = 20;
        let page = 1;
        if (result) {
            exams = result.data.records;
            total = result.data.total;
            limit = result.data.limit;
            page = result.page;
        }

        dispatch({
            type: 'PAGING',
            page: page
        });

        dispatch({
            type: ActionTypes.LIST_BY_LESSON,
            exams,
            total,
            limit
        });
    };
}

export function examDetail(params) {
    return async dispatch => {
        const result = await sendRequest('/exam/detail', 'POST', params);        
        let data = [];
        if (result) {
            data = result.data;
        }

        dispatch({
            type: ActionTypes.EXAM_DETAIL,
            data,
        });
    };
}

export function initAnswer(params) {
    return async dispatch => {
        await dispatch({ type: ActionTypes.INIT_ANSWER, answer: params });
    };
}

export function clickAnswer(params) {
    return async dispatch => {
        await dispatch({ type: ActionTypes.CLICK_ANSWER, answer: params });
    };
}

export function resetState() {
    return async (dispatch) => {
        await dispatch({ type: ActionTypes.RESET });
    };
}

export function createTesting(params) {
    return async dispatch => {
        const result = await sendRequest('/testing/create', 'POST', params);
        let statusCode = null;
        let dataCreate = {};
        if (result) {
            statusCode = result.code;
            dataCreate = result.data;
        }

        dispatch({
            type: ActionTypes.CREATE_TESTING,
            statusCode,
            dataCreate
        });
    };
}