import * as ActionTypes from './type';
import { sendRequest } from '../../config/api';

export function homeSlider(params) {
    return async dispatch => {
        const result = await sendRequest('/banner/preview', 'POST', params);
        let dataSlider = [];
        if (result) {
            dataSlider = result.data;
        }

        dispatch({
            type: ActionTypes.HOME_SLIDER,
            dataSlider
        });
    };
}

export function homeFeaturedBox(params) {
    return async dispatch => {
        const result = await sendRequest('/banner/preview', 'POST', params);
        let dataFeaturedBox = [];
        if (result) {
            dataFeaturedBox = result.data;
        }

        dispatch({
            type: ActionTypes.HOME_FEATURED_BOX,
            dataFeaturedBox
        });
    };
}

export function supportOnline(params) {
    return async dispatch => {
        const result = await sendRequest('/banner/preview', 'POST', params);
        let supportOnline = [];
        if (result) {
            supportOnline = result.data;
        }

        dispatch({
            type: ActionTypes.SUPPORT_ONLINE,
            supportOnline
        });
    };
}
