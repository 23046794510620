import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';

class Footer extends Component {
    constructor(props) {
        super();
        this.state = {

        };
    }

    render() {
        return (
            <div className="nap-footer">
                <div className="footer-top">
                    <svg fill="#04849f" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" preserveAspectRatio="none" viewBox="0 0 1920 148"><defs></defs><path className="cls-1" d="M1920,148H0L71.8,29.4A90.2,90.2,0,0,1,203.7,28l8.3,8.7a90.2,90.2,0,0,0,133-2.7l.2-.2a90.2,90.2,0,0,1,134.4-1.3l2.5,2.7a90.2,90.2,0,0,0,133.2,0l3.8-4.2a90.2,90.2,0,0,1,133.2,0l3.9,4.3a90.2,90.2,0,0,0,133.1.1l4.2-4.6a90.2,90.2,0,0,1,133,0l4.1,4.5a90.2,90.2,0,0,0,133.2-.2l3.7-4a90.2,90.2,0,0,1,133.2-.2l4.1,4.5a90.2,90.2,0,0,0,133,0l2.8-3.1A90.2,90.2,0,0,1,1575,33.8h0a90.2,90.2,0,0,0,133.1,2.9l8.3-8.7a90.2,90.2,0,0,1,131.9,1.5Z" transform="translate(0 0)" /><path className="cls-2" d="M1920,148H0L71.8,49.4A90.2,90.2,0,0,1,203.7,48l8.3,8.7a90.2,90.2,0,0,0,133-2.7l.2-.2a90.2,90.2,0,0,1,134.4-1.3l2.5,2.7a90.2,90.2,0,0,0,133.2,0l3.8-4.2a90.2,90.2,0,0,1,133.2,0l3.9,4.3a90.2,90.2,0,0,0,133.1.1l4.2-4.6a90.2,90.2,0,0,1,133,0l4.1,4.5a90.2,90.2,0,0,0,133.2-.2l3.7-4a90.2,90.2,0,0,1,133.2-.2l4.1,4.5a90.2,90.2,0,0,0,133,0l2.8-3.1A90.2,90.2,0,0,1,1575,53.8h0a90.2,90.2,0,0,0,133.1,2.9l8.3-8.7a90.2,90.2,0,0,1,131.9,1.5Z" transform="translate(0 0)" /><path className="cls-3" d="M1920,148H0L71.8,69.4A90.2,90.2,0,0,1,203.7,68l8.3,8.7a90.2,90.2,0,0,0,133-2.7l.2-.2a90.2,90.2,0,0,1,134.4-1.3l2.5,2.7a90.2,90.2,0,0,0,133.2,0l3.8-4.2a90.2,90.2,0,0,1,133.2,0l3.9,4.3a90.2,90.2,0,0,0,133.1.1l4.2-4.6a90.2,90.2,0,0,1,133,0l4.1,4.5a90.2,90.2,0,0,0,133.2-.2l3.7-4a90.2,90.2,0,0,1,133.2-.2l4.1,4.5a90.2,90.2,0,0,0,133,0l2.8-3.1A90.2,90.2,0,0,1,1575,73.8h0a90.2,90.2,0,0,0,133.1,2.9l8.3-8.7a90.2,90.2,0,0,1,131.9,1.5Z" transform="translate(0 0)" /></svg>
                </div>
                <div className="footer-content">
                    <Container>
                        <Row>
                            <Col md={4}>
                                <h2 className="nap-footer-title">Công ty cổ phần Giáo dục trực tuyến Việt Nam</h2>
                                <p>MST: 0106071064</p>
                                <p>Điện thoại: 02466860849</p>
                                <p>Địa chỉ : Số 86 Cù Chính Lan, phường Khương Mai, Thanh Xuân, Hà Nội, Việt Nam.</p>
                                <p>Người đại diện: Lương Văn Thuỳ</p>
                                <p>Chức vụ: Giám đốc</p>                                
                            </Col>
                            <Col md={2}>
                                <h2 className="nap-footer-title">Về NGOCHUYENLB</h2>
                                <ul className="nap-link-footer">
                                    <li>
                                        <NavLink to="">Giới thiệu</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="">Học sinh tiêu biểu</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="">Điều khoản chính sách</NavLink>
                                    </li>
                                </ul>
                            </Col>
                            <Col md={3}>
                                <h2 className="nap-footer-title">Dành cho học viên</h2>
                                <ul className="nap-link-footer">
                                    <li>
                                        <NavLink to="mailto:info@nap.vn">Email: huyenvu2405@gmail.com</NavLink>
                                    </li>
                                    <li>
                                        <p>Hotline : <NavLink to="tel:08 66 998912">08 66 998912</NavLink></p>
                                    </li>
                                    <li>
                                        <a target="blank" href="https://www.youtube.com/channel/UC45T4WflL9olLoP96v8d0BQ">
                                            <img style={{ background: '#fff', borderRadius: '10px', height: '40px' }} src="https://cdn.nap.edu.vn/assets/img/youtube-logo.png" title="" alt="" />
                                        </a>
                                    </li>
                                </ul>
                            </Col>
                            <Col md={3}>
                                <h2 className="nap-footer-title">HỌC TOÁN CÙNG CÔ HUYỀN</h2>
                                <a className="nap-link-fb-page" target="blank" href="https://facebook.com/groups/phacdotoan">NHÓM PHÁC ĐỒ TOÁN</a>
                                <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fngochuyenlb&tabs&width=255&height=214&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=338214013877928" width="340" height="214" style={{ border: 'none', overflow: 'hidden'}} scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>                                                                                                
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {},
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));
