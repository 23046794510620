import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import { listClassroom } from '../../redux/user/action';

class Row extends Component {
    constructor(props) {
        super();
        this.state = {};
    }

    render() {
        const { subject } = this.props.obj;
        return (
            <>
                <tr>
                    <td><a href={`/classroom/${this.props.obj.alias}`}>{this.props.obj.code}</a></td>
                    <td><a href={`/classroom/${this.props.obj.alias}`}>{this.props.obj.name}</a></td>
                    <td>{subject.name}</td>
                    <td>{this.props.obj.teacher}</td>
                </tr>
            </>
        );
    }
}

class Classroom extends Component {
    constructor(props) {
        super();
        this.state = {

        };
    }

    fetchRows() {
        if (this.props.classrooms instanceof Array) {
            return this.props.classrooms.map((object, i) => {
                return (
                    <Row
                        obj={object}
                        key={object._id}
                        index={i}
                        {...this.props}
                    />
                );
            });
        }
    }

    async componentDidMount() {
        await this.props.listClassroom();
        if (this.props.limit) {
            await this.setState({
                limit: this.props.limit,
            });
        }
    }

    render() {
        return (
            <>
                <div className="nap-classinvolved">
                    <div className="nap-title-classinvolved">
                        <h2 className="nap-title-profile">Lớp đã tham gia</h2>
                    </div>
                    <div className="nap-content-classinvolved">
                        <Table striped bordered hover className="nap-table-classinvolved">
                            <thead>
                                <tr>
                                    <th>Mã lớp</th>
                                    <th>Tên lớp học</th>
                                    <th>Môn học</th>
                                    <th>Giáo viên</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.fetchRows()}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    console.log('class: ' + state.user.classrooms);
    return {
        classrooms: state.user.classrooms,
        limit: state.user.limit,
        page: state.user.page,
        total: state.user.total,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { listClassroom },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Classroom));
