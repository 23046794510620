import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';

class Notification extends Component {
    constructor(props) {
        super();
        this.state = {

        };
    }

    render() {
        return (
            <>
            <div className="nap-classinvolved">
                <div className="nap-title-classinvolved">
                   Thông báo
                </div>
                <div className="nap-content-classinvolved">
                    <Table striped bordered hover className="nap-table-classinvolved">
                        <thead>
                            <tr>
                            <th>STT</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Username</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td>1</td>
                            <td>Mark</td>
                            <td>Otto</td>
                            <td>@mdo</td>
                            </tr>
                            <tr>
                            <td>2</td>
                            <td>Jacob</td>
                            <td>Thornton</td>
                            <td>@fat</td>
                            </tr>
                            <tr>
                            <td>3</td>
                            <td colSpan="2">Larry the Bird</td>
                            <td>@twitter</td>
                            </tr>
                        </tbody>
                    </Table>
                </div> 
            </div>
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {},
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notification));
