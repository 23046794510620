import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Navbar, Nav, Image, Modal } from 'react-bootstrap';
import { logout } from '../../redux/auth/action';
import { showProfile } from '../../redux/user/action'
import { imgURL } from '../../config/config';
import { TOKEN_KEY } from '../../config/app';
import cookie from 'react-cookies';
import { FiLogOut } from "react-icons/fi";
import { FiAlignJustify } from "react-icons/fi";
class Header extends Component {
    constructor(props) {
        super();
        this.state = {
            showWebsiteMessage: false
        };
    }
    

    handleClose = () => {
        this.setState({
            showWebsiteMessage: false
        });
    };

    handleCloseModal = () => {
        this.setState({
            showPayment: false
        });
    };

    handleShowPayment = () => {
        this.setState({
            showPayment: true
        });
    }

    logout = () => {
        this.props.logout();
    }

    async componentDidMount() {        
        if (cookie.load(TOKEN_KEY) !== undefined) {
            await this.props.showProfile();
        }
    }

    checkAuth() {
        if (cookie.load(TOKEN_KEY) === undefined) {
            return (
                <>
                    <NavLink className="nav-link nap-nav-item" to="/signin">Đăng nhập</NavLink>
                    <NavLink className="nav-link nap-nav-item" to="/signup">Đăng ký</NavLink>
                </>
            )
        }
        return (

            <>
                <Nav.Link className="nav-link nap-nav-item" href="/my/profile" title="Quản lý tài khoản">
                    <span style={{ float: 'left' }}>
                        Chào, {this.props.userInfo !== null ? this.props.userInfo.fullname : ''}
                    </span>
                    <div className="nap-avatar-header">
                        {
                            this.props.userInfo && this.props.userInfo.avatar ?
                                <img alt='' src={this.props.userInfo != null ? imgURL + this.props.userInfo.avatar : ''} /> : null
                        }
                    </div>
                </Nav.Link>
                <NavLink className="nav-link nap-nav-item nap-btn-logout" onClick={this.logout} to="/signin" title="Đăng xuất"><FiLogOut /></NavLink>
            </>

        )

    }

    render() {
        return (
            <div className="nap-header shadow-sm" id="nap-header">
                <div className="nap-nav">
                    <Container>
                        <Navbar expand="lg" className="p-0">
                            <div className="nap-logo">
                                <NavLink to="/" title="Trang chủ"><Image src="https://cdn.ngochuyenlb.edu.vn/assets/img/ngochuyenlb-logo-white.png?v=1.0" /></NavLink>
                            </div>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" className="nap-btn-navbar-nav-responsive">
                                <span style={{ fontSize: '30px', color: '#fff' }}>< FiAlignJustify /></span>
                            </Navbar.Toggle>
                            <Navbar.Collapse id="basic-navbar-nav" className="nap-navbar-nav-custom">
                                <Nav className="mr-auto">
                                    <Nav.Link className="nav-link nap-nav-item" href="/">Trang chủ</Nav.Link>
                                    <Nav.Link className="nav-link nap-nav-item" href="https://www.facebook.com/ngochuyenlb/" target="_blank">Giới thiệu</Nav.Link>
                                    <Nav.Link className="nav-link nap-nav-item" href="/khoa-hoc">Khóa học</Nav.Link>
                                    <Nav.Link className="nav-link nap-nav-item" href="/test-online">Phòng thi thử</Nav.Link>
                                    <Nav.Link className="nav-link nap-nav-item" href="/bang-xep-hang">Bảng xếp hạng</Nav.Link>
                                    <Nav.Link className="nav-link nap-nav-item" onClick={this.handleShowPayment}>Thanh toán</Nav.Link>
                                    {this.checkAuth()}
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                        <Modal show={this.state.showPayment} onHide={this.handleCloseModal} className="website-message">
                            <Modal.Header closeButton>
                                <Modal.Title>Thanh toán</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <h5>THANH TOÁN QUA TÀI KHOẢN NGÂN HÀNG </h5>
                                <em>Sau khi đăng kí học, các em chuyển khoản vào 1 trong 2 tài khoản sau, nội dung chuyển khoản ghi: “Họ tên + Phác đồ Toán”. Sau đó nhắn tin cho fan page “Ngọc Huyền LB”: m.me/ngochuyenlb (hoặc SĐT: 0688 99 89 12):</em>
                                <div className="bank_item">
                                    VPBank <br />
                                    <strong>STK:</strong> 248948809 <br />
                                    <strong>Chủ tài khoản:</strong> CONG TY TNHH MATHPRESSO VIET NAM <br />
                                    <strong>Chi nhánh:</strong> VPBank - CN Hà Thành, Hà Nội
                                </div>
                            </Modal.Body>
                        </Modal>
                    </Container>
                </div>
                <Modal show={this.state.showWebsiteMessage} onHide={this.handleClose} className="website-message">
                    <Modal.Header closeButton>
                        <Modal.Title>Thông báo!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Cô đang trong quá trình hoàn thiện video bài giảng và 07 cuốn sách trong <strong>PHÁC ĐỒ TOÁN</strong>. Hệ thống bài giảng và bài kiểm tra cho <strong>PHÁC ĐỒ TOÁN</strong> trên website sẽ chính thức khai giảng vào <strong>25/9/2020</strong><p>Đây chắc chắn sẽ là khóa học ấn tượng nhất, đẹp nhất và ...hiệu quả nhất mà các em có được. Chờ cô chút nhé!</p><p>Đăng kí để trở thành học sinh PHÁC ĐỒ TOÁN: <a href="https://m.me/ngochuyenlb">m.me/ngochuyenlb</a></p></Modal.Body>
                </Modal>
            </div >
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        token: state.auth.token,
        userInfo: state.user.userInfo || null,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { logout, showProfile },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
