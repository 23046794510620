import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Moment from "moment";
import { connect } from 'react-redux';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Header from '../layout/Header';
import Footer from '../layout/Footer';

import { listGroupClassroom } from '../../redux/classroom/action';


class TestOnline extends Component {
    constructor(props) {
        super();
        this.state = {
            limit: 20
        };
    }

    componentDidMount = async () => {
        const params = { type: "TEST_RANDOM" };
        await this.props.listGroupClassroom(params);
    };

    fetchClassroomCols() {
        if (this.props.classrooms instanceof Array) {
            return this.props.classrooms.map((object, i) => {
                return (
                    <Col md={3} xs={6}>
                        <Card className="group-item classroom-item">
                            <NavLink to={'/test-online/' + object.alias + "/chuyen-de"}><Card.Img variant="top" src={object.thumb} /></NavLink>
                            <Card.Body>
                                <Card.Title><NavLink to={'/test-online/' + object.alias + "/chuyen-de"}>{object.name}</NavLink></Card.Title>
                                <div className="classroom-desc">
                                    <div dangerouslySetInnerHTML={{ __html: object.description }} />
                                    <p className="khaigiang">Khai giảng:
                                    {object.created_at &&
                                            Moment(object.created_at).format(
                                                " DD/MM/YYYY"
                                            )}
                                    </p>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                );
            });
        }
    }

    render() {
        return (
            <>
                <Header />
                <div className="nap-main-content">
                    <Container>
                        <Row>
                            <Col md={12} className="box-page-title">
                                <h1 className="nap-page-title">{this.props.classroomGroup ? this.props.classroomGroup.name : 'Phòng Thi Thử'}</h1>
                            </Col>
                        </Row>
                        <Row>
                            {this.fetchClassroomCols()}
                        </Row>
                    </Container>
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {    
    return {
        classrooms: state.classroom.groupClassrooms
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { listGroupClassroom },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TestOnline));
