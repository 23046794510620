import * as ActionTypes from './type';
import { sendRequest } from '../../config/api';

export function listClassroom(params) {
	return async dispatch => {
		const result = await sendRequest('/classroom/list', 'POST', params);
		let total = 0;
		let classrooms = [];
		let limit = 20;
		let page = 1;
		if (result) {
			classrooms = result.data.records;
			total = result.data.total;
			limit = result.data.limit;
			page = result.page;
		}

		dispatch({
			type: 'PAGING',
			page: page
		});

		dispatch({
			type: ActionTypes.LIST_CLASSROOM,
			classrooms,
			total,
			limit
		});
	};
}

export function listClassroomGroupByYear(params) {
	return async dispatch => {
		const result = await sendRequest('/classroom-group/list-by-year', 'POST', params);
		let classroomGroupData = [];
		if (result) {
			classroomGroupData = result.data
		}

		dispatch({
			type: ActionTypes.LIST_GROUP_BY_YEAR,
			classroomGroupData
		});
	};
}

export function listGroupClassroom(params) {
	return async dispatch => {
		const result = await sendRequest('/classroom-group/list-classroom', 'POST', params);
		let groupClassrooms = [];
		let classroomGroup = null;
		if (result) {
			groupClassrooms = result.data.classrooms;
			classroomGroup = result.data.classroomGroup;
		}
		dispatch({
			type: ActionTypes.LIST_GROUP_CLASSROOM,
			groupClassrooms,
			classroomGroup
		});
	};
}

export function classroomPreview(params) {
	return async dispatch => {
		const result = await sendRequest('/classroom/preview', 'POST', params);
		let classroom = null;
		let classroomLessons = [];
		let classroomGroup = null;
		let joined = null;

		if (result) {
			classroom = result.data.classroom;
			classroomLessons = result.data.lessons;
			classroomGroup = result.data.classroomGroup;
			joined = result.data.joined;
		}


		dispatch({
			type: ActionTypes.LIST_CLASSROOM_PREVIEW,
			classroom,
			classroomLessons,
			classroomGroup,
			joined,
		});
	};
}


export function lessonPreview(params) {
	return async dispatch => {
		const result = await sendRequest('/lesson/preview', 'POST', params);
		let lessonVideos = [];
		let lesson = null;
		let classroom = null;
		let classroomGroup = null;
		if (result) {
			lessonVideos = result.data.lessonVideos;
			lesson = result.data.lesson;
			classroom = result.data.classroom;
			classroomGroup = result.data.classroomGroup;
		}


		dispatch({
			type: ActionTypes.LIST_LESSON_PREVIEW,
			lesson,
			lessonVideos,
			classroom,
			classroomGroup
		});
	};
}

export function LessonVideoPreview(params) {
	return async dispatch => {
		const result = await sendRequest('/lesson-video/preview', 'POST', params);
		let currentVideo = [];
		let lessonVideos = [];
		let numCanView = null;
		let lesson = null;
		let totalViewLimit = 0;
		if (result) {
			currentVideo = result.data.currentVideo;
			lessonVideos = result.data.lessonVideos;
			lesson = result.data.lesson;
			numCanView = result.data.numCanView;
			totalViewLimit = result.data.totalViewLimit;
		}

		dispatch({
			type: ActionTypes.LESSON_VIDEO_PREVIEW,
			currentVideo,
			lessonVideos,
			lesson,
			numCanView,
			totalViewLimit
		});
	};
}

export function classroomAccessByCode(params) {
	return async dispatch => {
		const result = await sendRequest('/classroom/access-by-code', 'POST', params);
		let dataAccessByCode = [];
		let stautsCode = null;
		if (result) {
			dataAccessByCode = result.data;
			stautsCode = result.code;
		}

		dispatch({
			type: ActionTypes.CLASSROOM_ACCESS_BY_CODE,
			dataAccessByCode,
			stautsCode,
		});
	};
}