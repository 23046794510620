import * as ActionTypes from './type';
const initialState = {
	userInfo: null,
	dataShowTesting: {},
	questions: [],
	total: 0,
	page: 1,
	limit: 100,

};
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.SHOW_PROFILE:
			return {
				...state,
				userInfo: action.userInfo,
			};
		case ActionTypes.LIST_CLASSROOM:
			return {
				...state,
				classrooms: action.classrooms,
				total: action.total,
				limit: action.limit,
				ids: [],
			};
		case ActionTypes.LIST_TESTING:
			return {
				...state,
				testings: action.testings,
				total: action.total,
				limit: action.limit,
			};
		case ActionTypes.SHOW_TESTING:
			let _answers = [];
			let questions = [];
			let total = 0;
			if (action.dataShowTesting) {
				_answers = Object.assign([], action.dataShowTesting.testing.answers);
				questions = Object.assign([], action.dataShowTesting.questions);
				total = questions.length;
			}

			if (_answers.length === 0) {
				questions.map((ele) => {
					ele.value = null;
					return ele;
				});
			} else {
				_answers.forEach((ans) => {
					questions.forEach((ele) => {
						if (ans.question_id === ele._id) {
							ans = Object.assign(ans, ele);
						}
					});
				});
			}

			const iterator = _answers.values();
			var qtyTrue = 0;
			for (const ele of iterator) {
				if (ele.value === ele.answer) {
					qtyTrue++;
				}
			}
			return {
				...state,
				dataShowTesting: action.dataShowTesting,
				questions: Object.assign(
					[],
					_answers.length === 0 ? questions : _answers
				),
				qtyTrue,
				total: total,
			};
		case ActionTypes.VIEW_ANSWER:
			return {
				...state,
				answerData: action.answerData,
			};
		case ActionTypes.LIST_RANK:
			return {
				...state,
				ranks: action.ranks,
				total: action.total,
				limit: action.limit,
			};
		case 'PAGING':
			return {
				...state,
				page: action.page,
			};
		default:
			return state;
	}
};

export default reducer;
