import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Col, Container, Breadcrumb } from 'react-bootstrap';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { FiHome, FiVideo } from "react-icons/fi";
import { IoIosBook } from "react-icons/io";
import { LessonVideoPreview } from "../../redux/classroom/action";
import Vimeo from '@u-wave/react-vimeo';

class LessonShow extends Component {
    constructor(props) {
        super();
        this.state = {

        };
    }

    componentDidMount = async () => {
        let params = { "alias": this.props.match.params.alias };
        await this.props.LessonVideoPreview(params);
    }

    fetchLinkVideo() {
        if (this.props.currentVideo instanceof Object) {
            let object = this.props.currentVideo;
            let arrLink;
            let vID;
            const videoLink = object.link ? object.link : null;
            if (videoLink && object.type === 'YOUTUBE') {
                if (object.link) {
                    arrLink = object.link.split('?v=');
                    vID = arrLink[1];
                }
                return (
                    <div className="nap-video-lesson">
                        <div className="overlay-video"></div>
                        <iframe title="Video" width="100%" height="640" src={`https://www.youtube.com/embed/${vID}?rel=0&modestbranding=1&playsinline=1&controls=1&enablejsapi=1&origin=https%3A%2F%2Fnap.edu.vn&widgetid=0`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                )
            } else if (object.type === 'VIMEO' || (videoLink && videoLink.indexOf('vimeo.com') >= 0)) {
                if (object.link) {
                    arrLink = object.link.split('https://player.vimeo.com/video/');
                    vID = arrLink[1];
                }
                return (
                    <Vimeo
                        vimeo-id={vID}
                        video={vID}
                        width="100%"
                        height={640}
                        autoplay={true}
                        speed={true}
                        responsive={true}
                        showTitle={false}
                        showByline={false}
                        showPortrait={false}
                    />
                )

                //border: none; position: absolute; top: 0; height: 100%; width: 100%;
            } else if (object.type === 'BUNNY_NET'){
                return (<div style={{position: 'relative', width: '100%'}}>
                    <iframe id='video-bunny-player' src={videoLink.replace('/play/', '/embed/')} loading="lazy" allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe>
                    </div>)
            
        } else {
                return (
                    <div className="nap-video-lesson">
                        <iframe title="Video" width="100%" height="640" src={` ${videoLink}/preview`} frameBorder="0" allow="autoplay; fullscreen"></iframe>
                    </div>
                )
            }

        }
    }

    fetchLessonVideos() {
        if (this.props.lessonVideos instanceof Array) {
            return this.props.lessonVideos.map((object, i) =>
                <li>
                    <a href={'/lesson-view/' + object.alias}>
                        <span class="nap-icon-lesson"><IoIosBook /></span>{object.name}</a>
                </li>
            )
        }
    }


    render() {
        return (
            <>
                <Header />
                <div className="nap-main-content">
                    <Container>
                        <Row>
                            <Col md={5} className="box-page-title">
                                <h1 className="nap-page-title">Bài giảng: {this.props.currentVideo ? this.props.currentVideo.name : ''}</h1>
                            </Col>
                            <Col md={7} className="box-breadcrumbs text-right">
                                <Row>
                                    <Col md={4}><span className="total_view">Còn <strong>{this.props.numCanView}/{this.props.totalViewLimit}</strong> lượt xem</span></Col>
                                    <Col md={8}>
                                        <Breadcrumb className="nap-breadcrumb">
                                            <Breadcrumb.Item href="/"><FiHome /></Breadcrumb.Item>
                                            <Breadcrumb.Item href={`/lesson/${this.props.lesson ? this.props.lesson.alias : ''}`}>{this.props.lesson ? this.props.lesson.name : ''}</Breadcrumb.Item>
                                        </Breadcrumb>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={9}>
                                {this.fetchLinkVideo()}
                            </Col>
                            <Col md={3}>
                                <div className="nap-lesson-box">
                                    <h2 className="nap-title-lesson"><FiVideo /> Danh sách bài giảng</h2>
                                    <ul className="nap-list-lesson">
                                        {this.fetchLessonVideos()}
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        lessonVideos: state.classroom.lessonVideos,
        currentVideo: state.classroom.currentVideo,
        classroomGroup: state.classroom.classroomGroup,
        classroom: state.classroom.classroom,
        lesson: state.classroom.lesson,
        numCanView: state.classroom.numCanView,
        totalViewLimit: state.classroom.totalViewLimit
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { LessonVideoPreview },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LessonShow));
