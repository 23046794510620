import * as ActionTypes from './type';
const initialState = {
    dataSlider: [],
    dataFeaturedBox: [],
    supportOnline: []
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.HOME_SLIDER:
            return {
                ...state,
                dataSlider: action.dataSlider,
            };
        case ActionTypes.HOME_FEATURED_BOX:
            return {
                ...state,
                dataFeaturedBox: action.dataFeaturedBox,
            };
        case ActionTypes.SUPPORT_ONLINE:
            return {
                ...state,
                supportOnline: action.supportOnline,
            };
        default:
            return state;
    }
};

export default reducer;
