import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { TiKeyOutline } from "react-icons/ti";
import { toast } from 'react-toastify';
import { changePassowrd } from '../../redux/user/action';

class ChangePassword extends Component {
    constructor(props) {
        super();
        this.state = {
            old_password: '',
            new_password: '',
            comfirm_password: '',
        };
    }

    handleChange = e => {
        var name = e.target.name;
        var value = e.target.value;
        this.setState({
            [name]:value,
        });
    }

    handleSubmit = async e => {
        e.preventDefault();
        const prams = {
            old_password: this.state.old_password,
            new_password: this.state.new_password,
        }

        if (this.state.new_password !== this.state.comfirm_password) {
            let msg = 'Mật khẩu không khớp với mật khẩu ở trên!';
            toast.warning(msg, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            await this.props.changePassowrd(prams);
        }
        
    }

    render() {
        return (
            <>
                <div className="nap-profile">
                     <h2 className="nap-title-profile">Thay đổi mật khẩu</h2>
                    <Form className="nap-form-changepass">
                        <Row>
                            <Col md={1}></Col>
                            <Col md={10}>
                                <Row>
                                    <Col md={12}>
                                        <Row>
                                            <Col md={4}>
                                                <span className="nap-info-changepass">
                                                    <span className="nap-icon-profile"><TiKeyOutline /></span>Mật khẩu cũ</span>
                                            </Col>
                                            <Col md={8}>
                                                <Form.Group >
                                                    <Form.Control type="password" name="old_password" onChange={this.handleChange}
                                                    value={this.state.old_password} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={12}>
                                        <Row>
                                            <Col md={4}>
                                                <span className="nap-info-changepass">
                                                    <span className="nap-icon-profile"><TiKeyOutline /></span>Mật khẩu mới</span>
                                            </Col>
                                            <Col md={8}>
                                                <Form.Group >
                                                    <Form.Control type="password" name="new_password" onChange={this.handleChange}
                                                    value={this.state.new_password} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={12}>
                                        <Row>
                                            <Col md={4}>
                                                <span className="nap-info-changepass">
                                                    <span className="nap-icon-profile"><TiKeyOutline /></span>Nhập lại mật khẩu</span>
                                            </Col>
                                            <Col md={8}>
                                                <Form.Group >
                                                    <Form.Control type="password" name="comfirm_password" onChange={this.handleChange}
                                                    value={this.state.comfirm_password} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={12}>
                                        <Button className="nap-btn-profile" variant="primary" type="submit" onClick={this.handleSubmit}>
                                            Xác nhận
                            </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {changePassowrd},
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangePassword));
