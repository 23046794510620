import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Col, Card } from 'react-bootstrap';

class CategoryGroup extends Component {
    constructor(props) {
        super();
        this.state = {

        };
    }

    fetchGroupCols() {
        if (this.props.obj.groups instanceof Array) {
            return this.props.obj.groups.map((object, i) => {
                return (
                    <Col md={3} xs={6}>
                        <Card className="group-item">
                            <NavLink to={'/category/' + object.alias}><Card.Img variant="top" src={object.thumb} /></NavLink>
                            <Card.Body>
                                <Card.Title><NavLink to={'/category/' + object.alias}>{object.name}</NavLink></Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                );
            });
        }
    }

    render() {
        const group = this.props.obj;
        return (
            <Row>
                <Col>
                    <div className="nap-category-group">
                        <div className="nap-category-group-head">{group.name}</div>
                        <div className="nap-category-group-items">
                            <Row>
                                {this.fetchGroupCols()}
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {},
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CategoryGroup));
