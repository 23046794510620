import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Form, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import { listTesting } from '../../redux/user/action';
import { IoIosSearch } from "react-icons/io";

class RowTesting extends Component {
    constructor(props) {
        super();
        this.state = {}
    }

    render() {
        return (
            <>
                <tr>
                    <td>
                        <NavLink className="nap-link-testing" to={`/testing/${this.props.obj._id}`}>
                            {this.props.obj.exam.code}</NavLink>
                    </td>
                    <td>{this.props.obj.exam.name}</td>
                    <td>{this.props.obj.user.code}</td>
                    <td>{this.props.obj.user.name}</td>
                    <td>{this.props.obj.classroom.name}</td>
                    <td>{this.props.obj.subject.name}</td>
                    <td>{this.props.obj.status !== 'REVIEW'
                        ? Math.round(this.props.obj.point * 10) / 10
                        : null}</td>
                </tr>
            </>
        );
    }
}
class Testing extends Component {
    constructor(props) {
        super();
        this.state = {
            keyword: '',
        };
    }

    fetchRows() {
        if (this.props.testings instanceof Array) {
            return this.props.testings.map((object, i) => {
                return (
                    <RowTesting
                        obj={object}
                        key={object._id}
                        index={i}
                        {...this.props}
                    />
                );
            });
        }
    }

    async componentDidMount() {
        await this.props.listTesting(this.getData());
        if (this.props.limit) {
            await this.setState({
                limit: this.props.limit,
            });
        }
    }

    getData = (pageNumber = 1) => {
        const data = {
            page: pageNumber,
            limit: this.state.limit,
        };
        if (this.state.keyword != null) {
            data['keyword'] = this.state.keyword;
        }
        return data;
    };

    handleChange = e => {
        var name = e.target.name;
        var value = e.target.value;
        this.setState({
            [name]: value,
        });
        this.props.listTesting(this.getData());
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.listTesting(this.getData());
    }

    render() {
        return (
            <>
                <div className="nap-classinvolved">
                    <div className="nap-title-classinvolved">
                        <h2 className="nap-title-profile">Bài đã làm</h2>
                    </div>
                    <div className="nap-search-testing">
                        <Form>
                            <Form.Group className="nap-control-search-testing" controlId="formBasicEmail">
                                <Form.Control type="text" name="keyword" placeholder="Tìm kiềm theo mã đề, học sinh..."
                                    onChange={this.handleChange} value={this.state.keyword} />
                            </Form.Group>
                            <Button className="nap-sbt-search-testing" variant="primary" type="submit"
                                onClick={this.handleSubmit}>
                                <IoIosSearch />Tìm kiếm
                                </Button>
                        </Form>
                    </div>
                    <div className="nap-content-classinvolved">

                        <Table striped bordered hover className="nap-table-classinvolved">
                            <thead>
                                <tr>
                                    <th>Mã đề</th>
                                    <th>Tên đề</th>
                                    <th>MÃ HS</th>
                                    <th>Tên HS</th>
                                    <th>Lớp</th>
                                    <th>Môn học</th>
                                    <th>Điểm</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.fetchRows()}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        testings: state.user.testings,
        limit: state.user.limit,
        page: state.user.page,
        total: state.user.total,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { listTesting },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Testing));
