
export const EXAM_DETAIL = "EXAM_DETAIL";
export const CREATE_TESTING = "CREATE_TESTING";
export const CLICK_ANSWER = "CLICK_ANSWER";
export const RESET = "RESET";
export const INIT_ANSWER = "INIT_ANSWER";
export const VIEW_ANSWER = "VIEW_ANSWER";
export const RESET_VIEWDATA = "RESET_VIEWDATA";
export const EXAM_PREVIEW = "EXAM_PREVIEW";
export const ERROR = "ERROR";
export const ERROR_RESET = "ERROR_RESET";
export const LIST_BY_LESSON = "LIST_BY_LESSON";
