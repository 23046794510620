import cookie from 'react-cookies';
import { URL_LOGIN, URL_PROFILE, TOKEN_KEY } from '../config/app';

export const checkLogin = () => {
    const token = cookie.load(TOKEN_KEY);
    if (!token)
        window.location.href = URL_LOGIN;
}

export const goDashboard = () => {
    const token = cookie.load(TOKEN_KEY);
    if (token)
        window.location.href = URL_PROFILE;
}