import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Moment from "moment";
import { connect } from 'react-redux';
import { Container, Row, Col, Card, Breadcrumb } from 'react-bootstrap';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { FiHome } from "react-icons/fi";

import { listGroupClassroom } from '../../redux/classroom/action';

const currencyVN = new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
    minimumFractionDigits: 0
})

class Classroom extends Component {
    constructor(props) {
        super();
        this.state = {
            limit: 20
        };
    }

    componentDidMount = async () => {
        const params = { alias: this.props.match.params.alias };        
        await this.props.listGroupClassroom(params);
    };

    fetchClassroomCols() {
        if (this.props.groupClassrooms instanceof Array) {
            return this.props.groupClassrooms.map((object, i) => {
                return (
                    <Col md={3} xs={6}>
                        <Card className="group-item classroom-item">
                            <NavLink to={'/classroom/' + object.alias}><Card.Img variant="top" src={object.thumb} /></NavLink>
                            <Card.Body>
                                <Card.Title><NavLink to={'/classroom/' + object.alias}>{object.name}</NavLink></Card.Title>
                                <div className="classroom-desc">
                                    <div dangerouslySetInnerHTML={{ __html: object.description }} />
                                    <p className="khaigiang">Khai giảng:
                                    {object.started_at &&
                                            Moment(object.started_at).format(
                                                " DD/MM/YYYY"
                                            )}
                                    </p>
                                </div>

                            </Card.Body>
                            <Card.Footer>
                                <div className="hocphi">Học phí: <span>{currencyVN.format(object.price)}</span></div>
                                <div className="hocphigoc">Học phí gốc: <span>{currencyVN.format(object.discount_price)}</span></div>
                            </Card.Footer>
                        </Card>
                    </Col>
                );
            });
        }
    }

    render() {
        return (
            <>
                <Header />
                <div className="nap-main-content">
                    <Container>
                        <Row>
                            <Col md={6} className="box-page-title">
                                <h1 className="nap-page-title">{this.props.classroomGroup ? this.props.classroomGroup.name : ''}</h1>
                            </Col>
                            <Col md={6} className="box-breadcrumbs text-right">
                                <Breadcrumb className="nap-breadcrumb">
                                    <Breadcrumb.Item href="/"><FiHome /></Breadcrumb.Item>
                                    <Breadcrumb.Item active href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
                                        {this.props.classroomGroup ? this.props.classroomGroup.name : ''}
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                        </Row>
                        <Row>
                            {this.fetchClassroomCols()}
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div className="nap-classroom-content">
                                    <div dangerouslySetInnerHTML={{
                                        __html: this.props.classroomGroup ? this.props.classroomGroup.content : '',
                                    }}></div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        groupClassrooms: state.classroom.groupClassrooms,
        classroomGroup: state.classroom.classroomGroup        
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { listGroupClassroom },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Classroom));
