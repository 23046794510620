import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import SupportBox from '../layout/SupportBox';
import HomeSlider from '../home/Slider';
import HomeFeaturedBox from '../home/HomeFeaturedBox ';
import CategoryGroup from '../classroom/CategoryGroup';

import { listClassroomGroupByYear } from '../../redux/classroom/action';

class Home extends Component {
    constructor(props) {
        super();
        this.state = {

        };
    }

    componentDidMount = async () => {
        await this.props.listClassroomGroupByYear();
    };

    fetchCategoryGroupRows() {
        if (this.props.classroomGroupData instanceof Array) {
            return this.props.classroomGroupData.map((object, i) => {
                return (
                    <CategoryGroup
                        obj={object}
                        key={object._id}
                        index={i}
                        {...this.props}
                    />
                );
            });
        }
    }

    render() {
        return (
            <>
                <Header />
                <div className="nap-main-content">
                    <Container>
                        <div className="nap-content-top">
                            <Row>
                                <Col md={9} xs={12} className="box-slider">
                                    <HomeSlider />
                                </Col>
                                <Col md={3} xs={12} className="box-support">
                                    <SupportBox />
                                </Col>
                            </Row>
                            <div>
                                <HomeFeaturedBox />
                            </div>
                        </div>
                        {this.fetchCategoryGroupRows()}
                    </Container>
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        classroomGroupData: state.classroom.classroomGroupData
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { listClassroomGroupByYear },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
