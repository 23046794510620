import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Col, Container, Breadcrumb } from 'react-bootstrap';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { FiVideo, FiAward, FiHome } from "react-icons/fi";
import { IoIosBook } from "react-icons/io";
import { lessonPreview } from "../../redux/classroom/action";
import { listExamByLesson } from "../../redux/exam/action";

class Lesson extends Component {
    constructor(props) {
        super();
        this.state = {
        };
    }

    componentDidMount = async () => {
        let params = { alias: this.props.match.params.alias };
        await this.props.lessonPreview(params);
        if (this.props.lesson) {
            await this.props.listExamByLesson({
                lesson_id: this.props.lesson._id,
                classroom_id: this.props.lesson.classroom.id
            });
        }
    };

    fetchlessonVideos() {
        if (this.props.lessonVideos instanceof Array) {
            return this.props.lessonVideos.map((object, i) =>
                <li>
                    <NavLink to={'/lesson-view/' + object.alias}>
                        <span class="nap-icon-lesson"><IoIosBook /></span>{object.name}</NavLink>
                </li>
            )
        }
    }


    fetchExams() {
        if (this.props.exams instanceof Array) {
            return this.props.exams.map((object, i) =>
                <NavLink to={`/do-exam/${object._id}-${this.props.lesson._id}-${this.props.lesson.classroom.id}`} className="nap-lesson-testing">{object.name}</NavLink>
            )
        }
    }

    render() {
        return (
            <>
                <Header />
                <div className="nap-main-content">
                    <Container>
                        <Row>
                            <Col md={5} className="box-page-title">
                                <h1 className="nap-page-title">{this.props.lesson ? this.props.lesson.name : ''}</h1>
                            </Col>
                            <Col md={7} className="box-breadcrumbs text-right">
                                <Breadcrumb className="nap-breadcrumb">
                                    <Breadcrumb.Item href="/"><FiHome /></Breadcrumb.Item>
                                    <Breadcrumb.Item href={`/category/${this.props.classroomGroup ? this.props.classroomGroup.alias : ''}`}>
                                        {this.props.classroomGroup ? this.props.classroomGroup.name : ''}
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item href={`/classroom/${this.props.classroom ? this.props.classroom.alias : ''}`}>
                                        {this.props.classroom ? this.props.classroom.name : ''}
                                    </Breadcrumb.Item>                                    
                                </Breadcrumb>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4} className="nap-lesson-left">
                                <div className="nap-lesson-box">
                                    <h2 className="nap-title-lesson"><FiVideo /> Danh sách bài giảng</h2>
                                    <ul className="nap-list-lesson">
                                        {this.fetchlessonVideos()}
                                    </ul>
                                </div>
                            </Col>
                            <Col md={8} className="nap-lesson-right">
                                <div className="nap-test-lesson">
                                    <h2 className="nap-title-lesson"><FiAward /> Danh sách bài kiểm tra</h2>
                                    <Col md={12} className="nap-clomn-test">
                                        {this.fetchExams()}
                                    </Col>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        lesson: state.classroom.lesson,
        lessonVideos: state.classroom.lessonVideos,
        classroom: state.classroom.classroom,
        classroomGroup: state.classroom.classroomGroup,
        exams: state.exam.exams,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { lessonPreview, listExamByLesson },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Lesson));
